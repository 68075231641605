<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogDetails"
      width="900"
      style="z-index: 999999999999999999"
    >
      <v-card style="border-radius: 10px; padding: 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Editar usuario
              </p>
            </v-col>
          </v-row>
          <!-- inputs content -->
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <v-text-field
                  v-model="nombreUsuario"
                  class="text-Poppins"
                  label="Nombre*"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="12">
              <div>
                <v-text-field
                  v-model="correoUsuario"
                  class="text-Poppins"
                  label="Correo eléctronico*"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" sm="12">
              <div>
                <!-- <v-text-field
                  v-model="puestoUsuario"
                  class="text-Poppins"
                  label="Puesto*"
                  style="margin-bottom: -10px"
                ></v-text-field> -->
                <v-select
                  v-model="puestoUsuario"
                  item-text="sName"
                  item-value="sId"
                  :items="itemsPuesto"
                  class="custom-placeholder"
                  label="Puesto*"
                >
                </v-select>
              </div>
            </v-col>
            <v-col cols="12" sm="12">
              <div>
                <v-text-field
                  v-model="telefonoUsuario"
                  class="text-Poppins"
                  label="Teléfono*"
                  style="margin-bottom: -10px"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <!-- checkboxes content -->
          <v-row>
            <v-col
              v-for="(item, index) in rolesUsuario"
              :key="index"
              cols="12"
              sm="6"
              md="6"
            >
              <v-checkbox
                v-model="aPermissions"
                :label="setRoles(item.sName)"
                :value="item.sRoleId"
                :checked="aPermissions"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- buttons content -->
          <br />
          <br />
          <v-row>
            <v-col cols="12" sm="3">
              <div>
                <v-btn
                  x-large
                  color="red"
                  width="175"
                  outlined
                  class="text-Poppins"
                  style="
                    height: 50px;
                    width: 100%;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                  @click="deleteProjects"
                  >Eliminar
                  <v-icon size="15">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3">
              <div>
                <v-btn
                  x-large
                  color="black"
                  width="175"
                  outlined
                  class="text-Poppins"
                  @click="close"
                  style="
                    height: 50px;
                    width: 100%;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                  >Cancelar
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3" class="text-right">
              <div>
                <v-btn
                  x-large
                  color="primary"
                  class="text-Poppins"
                  style="
                    width: 100%;
                    height: 50px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                  "
                  @click="changePassword"
                  >Cambiar contraseña
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3" class="text-right">
              <div>
                <v-btn
                  x-large
                  color="primary"
                  class="text-Poppins"
                  style="
                    width: 100%;
                    height: 50px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                  "
                  @click="saveChanges"
                  :loading="loadingButtonGuardarCambios"
                  >Guardar cambios
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DetailsUsers",
  props: {
    openDialogDetails: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      itemsPuesto: [
        {
          sName: "Administrativo",
          sId: "1",
        },
        {
          sName: "Residente",
          sId: "2",
        },
        {
          sName: "Director",
          sId: "3",
        },
      ],
      itemsCheckbox: [],
      aPermissions: [],
      nombreUsuario: "",
      correoUsuario: "",
      puestoUsuario: "",
      telefonoUsuario: "",
      rolesUsuario: "",
      loadingButtonGuardarCambios: false,
    };
  },
  watch: {
    openDialogDetails: function () {
      if (this.openDialogDetails) {
        this.allData();
      }
    },
    closeDialogDetails: function () {
      if (!this.$store.state.refresh) {
        this.close();
      }
    },
  },
  methods: {
    saveChanges() {
      this.loadingButtonGuardarCambios = true;

      const params = {
        sEmail: this.correoUsuario,
        sFullName: this.nombreUsuario,
        sPhoneNumber: this.telefonoUsuario,
        sPosition: this.puestoUsuario,
        aPermissions: this.aPermissions,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };

      db.put(`${uri}/api/v1/users/${this.itemDetails.sUserId}`, params, config)
        .then((resp) => {
          this.loadingButtonGuardarCambios = false;
          this.close();
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonGuardarCambios = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setCheckbox() {
      var items = [];
      for (let i = 0; i < this.itemsCheckbox.length; i++) {
        const element = this.itemsCheckbox[i];
        items.push(element.sRoleId);
      }
      this.aPermissions = items;
    },
    changePassword() {
      this.close();
      this.$emit("openDialogChangePassword", this.itemDetails);
    },
    deleteProjects() {
      var item = this.itemDetails;
      this.$store.commit("setDeleteItems", {
        id: item.sUserId,
        route: "/api/v1/users/" + item.sUserId,
        unit: " usuarios.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    allData() {
      this.getRoles();
      this.nombreUsuario = this.itemDetails.sFullName;
      this.correoUsuario = this.itemDetails.sEmail;
      //this.puestoUsuario = this.itemDetails.sPosition;
      this.puestoUsuario = {
        sName: this.itemDetails.sPosition,
        sId: this.itemDetails.iPositionId.toString(),
      };
      this.telefonoUsuario = this.itemDetails.sPhoneNumber;
      this.itemsCheckbox = this.itemDetails.aPermissions;
      this.setCheckbox();
    },
    close() {
      this.$emit("close");
    },
    setRoles(e) {
      var diccionario = {
        Administrator: "Administrador",
        PurchaseOrder: "Alta de órdenes de compra",
        ManagementPurchaseOrders: "Gestion de órdenes de compra",
        Supplier: "Alta de Proveedores",
        Projects: "Creación de proyectos",
        RequestForMaterials: "Solicitud de material",
        PurchaseUser: "Alta de usuarios",
        ExpenseManagement: "Gestión de gastos",
      };

      return diccionario[e];
    },
    getRoles() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/roles/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.rolesUsuario = resp.data.roles.filter((e) => {
            if (!e.sName.includes("Administrator")) {
              return e;
            }
          });
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  computed: {
    closeDialogDetails() {
      return this.$store.state.refresh;
    },
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
  },
};
</script>

<style scoped>
.v-dialog__content--active .v-input--checkbox {
  margin-bottom: -39px;
}
</style>